<template>
    <article
        class="card h-100 hover-pointer"
        @click="$router.push({ name: blog.link })"
    >
        <div class="card-img-top position-relative">
            <img
                class="card-img-top"
                :src="getImgUrl(blog.photo)"
                alt="Image Description"
            />
        </div>

        <div class="card-body">
            <h3 class="card-link-header">{{blog.header}}</h3>
            <p>
                {{ blog.subheader }}
            </p>
        </div>

        <div class="card-footer border-0 pt-0">
            <div class="media align-items-center">
                <div class="avatar-group"></div>
                <div
                    class="media-body d-flex justify-content-end text-muted font-size-1 ml-2"
                >
                    {{ blog.date }}
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    props: ['blog'],
    data() {
        return {loading: true,};
    },
    mounted() {
        this.loading = false;
    },
    methods: {
        getImgUrl(image) {
            var images = require.context('@/assets/img/', false, /\.jpg$/);
            return images('./' + image + '.jpg');
        },
    },
};
</script>
