<template>
    <div class="landing-wrapper">
        <t-header />
        <div class="container space-top-2 space-bottom-1">
            <div class="w-md-80 w-lg-75 text-center mx-md-auto">
                <h1 class="display-4">Learn More</h1>
                <p class="lead">
                    The latest updates and the most important resources to have
                    as a nonresident alien filing U.S. taxes
                </p>
            </div>
        </div>
        <div class="container space-bottom-2">
            <div class="row mb-3">
                <div
                    v-for="blog in blogs"
                    :key="blog.header"
                    class="col-sm-6 col-lg-4 mb-3 mb-sm-8"
                >
                    <!-- Blog Card -->
                    <BlogLink :blog="blog" />

                    <!-- End Blog Card -->
                </div>
            </div>
        </div>
        <t-footer />
    </div>
</template>

<script>
import BlogLink from './blog_link';

export default {
    data() {
        return {
            blogs: [
                {
                    header: `The Complete Tax Guide for F-1 / J-1 International Students in the U.S.`,
                    subheader: `F-1 and J-1 residency status determination, what types of income to report, and how to file your tax return as an international student.`,
                    date: 'Mar 25 2021',
                    photo: 'students_2',
                    link: 'InternationalStudentGuideBlog',
                },
                {
                    header: `Resident Alien vs. Non-resident Alien – A Complete Guide Including
						Status Determination and U.S. Tax Filing Requirements`,
                    subheader: `How your residency status for tax purposes is determined, 
					what your filing requirements are, and the pros and cons of each status –
					the ultimate U.S. tax filing guide for foreigners.`,
                    date: 'Mar 13 2021',
                    photo: 'students',
                    link: 'ResidentVsNonResidentBlog',
                },
                {
                    header: 'Covid-19 and U.S. Filing Requirements',
                    subheader:
                        'Studying remotely and unsure about your U.S. tax filing requirements? Read more.',
                    date: 'Jan 20 2021',
                    photo: 'remote_study',
                    link: 'CovidBlog',
                },
                {
                    header:
                        'How to file a U.S. tax return as an international student',
                    subheader:
                        'International students may be required to file a U.S. tax return if they have income from the U.S.',
                    date: 'Jan 5 2021',
                    photo: 'remote_study_2',
                    link: 'StudentFilingBlog',
                },
            ],
        };
    },
    components: {
        BlogLink: BlogLink,
    },
    methods: {},
    metaInfo: {
        title: 'Thriftax - Blog',
        meta: [
            {
                name: 'description',
                content: `Learn to file your nonresident taxes, special filing benefits for international students, how to file form 8843. 
				Thriftax's blog provides
				resources to help you navigate through your U.S. tax filing experience
				`,
            },
        ],
    },
};
</script>
